import styled from "styled-components"

export default styled.footer`
  background-color: var(--red);

  .footer-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 6rem;
    padding-bottom: 4rem;

    p {
      font-size: 1.6rem;
      color: white;
      padding-bottom: 1rem;
      text-align: center;
      max-width: 50rem;
    }

    .buttons {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      a {
        margin: 1rem;
      }
    }
  }

  .footer-menus {
    display: grid;
    grid-template-columns: repeat(5, 16%);
    grid-gap: 5%;
    padding-top: 7rem;
    padding-bottom: 7rem;

    @media (max-width: 980px) {
      grid-template-columns: repeat(2, 45%);
      grid-gap: 8%;
    }

    .menu {
      h4 {
        color: white;
        font-family: medium;
        font-size: 1.3rem;
        margin-bottom: 1.3rem;
      }

      a {
        display: block;
        color: white;
        font-family: thin;
        margin-bottom: 1rem;
        font-size: 1.3rem;

        &:hover {
          opacity: 0.8;
        }
      }

      .socialLink {
        margin: 0px;
      }
    }

    .newsletter {
      @media only screen and (max-width: 600px) {
        grid-column: 1/3;
      }

      ._form_6 form {
        @media (max-width: 980px) {
          margin-bottom: 5rem !important;
        }
        ._form-content {
          & > div {
            margin-bottom: 1rem !important;
          }

          #_form_6_submit {
            font-size: 1.4rem !important;
          }
        }
      }

      form {
        input[type="email"] {
          border: 0;
          background-color: rgba(255, 255, 255, 0.2);
          padding: 1rem 2rem;
          border-radius: 4px;
          color: white;

          &::placeholder {
            color: white;
            opacity: 0.4;
          }
        }

        input[type="submit"] {
          background-color: transparent;
          border: 0;
          color: white;
          text-decoration: underline;
          margin-top: 2rem;
          margin-bottom: 2rem;
          font-family: regular;
          font-size: 1.2rem;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }

  .footer-bottom {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.4rem 0;

    @media (max-width: 600px) {
      flex-direction: column;
      align-items: flex-start;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: 1px;
      background-color: white;
      opacity: 0.5;
    }

    img {
      max-width: 16rem;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }

      @media (max-width: 600px) {
        margin-bottom: 1rem;
      }
    }

    .footer-logo {
      :hover {
        opacity: 0.5;
      }
      @media only screen and (max-width: 600px) {
        display: none;
      }
    }

    .links {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;

      @media (max-width: 600px) {
        justify-content: flex-start;
      }

      a {
        font-family: thin;
        margin-left: 2rem;
        font-size: 1.3rem;
        color: white;
        opacity: 0.5;

        @media (max-width: 600px) {
          margin-left: 0;
          margin-right: 2rem;
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }
`
