import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import MyLink from "../sub/myLink"

const FooterBottomBar = () => {
  const {
    footer: { footerBottomBar },
  } = useStaticQuery(graphql`
    query {
      footer: sanityFooter {
        footerBottomBar {
          logo {
            ...sanityFlightImage
          }
          links {
            _key
            linkText
            url
          }
        }
      }
    }
  `)

  return (
    <div className="footer-bottom">
      <Link className="footer-logo" to="/">
        <img
          src={footerBottomBar.logo.asset.fluid.src}
          alt={footerBottomBar.logo.altText}
        />
      </Link>
      <div className="links">
        {footerBottomBar.links.map(link => (
          <MyLink key={link._key} url={link.url}>
            {link.linkText}
          </MyLink>
        ))}
      </div>
    </div>
  )
}

export default FooterBottomBar
