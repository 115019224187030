// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-apply-tsx": () => import("./../../../src/pages/apply.tsx" /* webpackChunkName: "component---src-pages-apply-tsx" */),
  "component---src-pages-blogs-tsx": () => import("./../../../src/pages/blogs.tsx" /* webpackChunkName: "component---src-pages-blogs-tsx" */),
  "component---src-pages-calculator-thank-you-tsx": () => import("./../../../src/pages/calculator-thank-you.tsx" /* webpackChunkName: "component---src-pages-calculator-thank-you-tsx" */),
  "component---src-pages-calculators-borrowing-tsx": () => import("./../../../src/pages/calculators/borrowing.tsx" /* webpackChunkName: "component---src-pages-calculators-borrowing-tsx" */),
  "component---src-pages-calculators-development-feasibility-tsx": () => import("./../../../src/pages/calculators/development-feasibility.tsx" /* webpackChunkName: "component---src-pages-calculators-development-feasibility-tsx" */),
  "component---src-pages-calculators-finance-cost-tsx": () => import("./../../../src/pages/calculators/finance-cost.tsx" /* webpackChunkName: "component---src-pages-calculators-finance-cost-tsx" */),
  "component---src-pages-calculators-mortgage-repayment-tsx": () => import("./../../../src/pages/calculators/mortgage-repayment.tsx" /* webpackChunkName: "component---src-pages-calculators-mortgage-repayment-tsx" */),
  "component---src-pages-calculators-useable-equity-calculator-tsx": () => import("./../../../src/pages/calculators/useable-equity-calculator.tsx" /* webpackChunkName: "component---src-pages-calculators-useable-equity-calculator-tsx" */),
  "component---src-pages-contact-ben-tsx": () => import("./../../../src/pages/contact-ben.tsx" /* webpackChunkName: "component---src-pages-contact-ben-tsx" */),
  "component---src-pages-contact-peter-tsx": () => import("./../../../src/pages/contact-peter.tsx" /* webpackChunkName: "component---src-pages-contact-peter-tsx" */),
  "component---src-pages-contact-us-thank-you-tsx": () => import("./../../../src/pages/contact-us-thank-you.tsx" /* webpackChunkName: "component---src-pages-contact-us-thank-you-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-privacy-statement-tsx": () => import("./../../../src/pages/privacy-statement.tsx" /* webpackChunkName: "component---src-pages-privacy-statement-tsx" */),
  "component---src-pages-public-disclosure-statement-tsx": () => import("./../../../src/pages/public-disclosure-statement.tsx" /* webpackChunkName: "component---src-pages-public-disclosure-statement-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-services-business-loans-tsx": () => import("./../../../src/pages/services/business-loans.tsx" /* webpackChunkName: "component---src-pages-services-business-loans-tsx" */),
  "component---src-pages-services-commercial-property-finance-tsx": () => import("./../../../src/pages/services/commercial-property-finance.tsx" /* webpackChunkName: "component---src-pages-services-commercial-property-finance-tsx" */),
  "component---src-pages-services-development-finance-tsx": () => import("./../../../src/pages/services/development-finance.tsx" /* webpackChunkName: "component---src-pages-services-development-finance-tsx" */),
  "component---src-pages-services-home-loans-tsx": () => import("./../../../src/pages/services/home-loans.tsx" /* webpackChunkName: "component---src-pages-services-home-loans-tsx" */),
  "component---src-pages-services-property-investment-lending-tsx": () => import("./../../../src/pages/services/property-investment-lending.tsx" /* webpackChunkName: "component---src-pages-services-property-investment-lending-tsx" */),
  "component---src-pages-terms-conditions-tsx": () => import("./../../../src/pages/terms-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-conditions-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */),
  "component---src-pages-website-disclaimer-tsx": () => import("./../../../src/pages/website-disclaimer.tsx" /* webpackChunkName: "component---src-pages-website-disclaimer-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blogTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-content-hub-template-tsx": () => import("./../../../src/templates/contentHubTemplate.tsx" /* webpackChunkName: "component---src-templates-content-hub-template-tsx" */),
  "component---src-templates-guide-page-template-tsx": () => import("./../../../src/templates/guidePageTemplate.tsx" /* webpackChunkName: "component---src-templates-guide-page-template-tsx" */),
  "component---src-templates-guide-thank-you-template-tsx": () => import("./../../../src/templates/guideThankYouTemplate.tsx" /* webpackChunkName: "component---src-templates-guide-thank-you-template-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/pageTemplate.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */)
}

