import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
  // reset ant design default style
  .ant-tabs {
    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: var(--red);
        opacity: 1;
        font-family: medium;
      }
    }

    .ant-tabs-tab-btn {
      color: var(--red);
      opacity: 0.4;

      &:hover {
        opacity: 0.7;
      }
    }

    .ant-tabs-ink-bar {
      background-color: var(--red);
    }

    .ant-tabs-nav::before {
      border-bottom: 2px solid rgba(93,42,52,0.1);
    }
  }


  .ant-input-number {
    box-shadow: unset!important;
    &:hover, &:focus {
      border-color: var(--red)!important;
    }

    span svg {
      fill: var(--red);
    }

    input {
      background-color: #e9e2dc;
      border-color: #daceca;
    }
  }

  .ant-slider {
    .ant-slider-rail {
      background-color: #b39a9b!important;
    }

    .ant-slider-track {
      background-color: var(--red)!important;
    }

    .ant-slider-dot {
      display: none;
    }

    .ant-slider-handle {
      border: 0;
      background-color: var(--red)!important;

      &:focus {
        box-shadow: 0 0 0 5px rgba(93,42,52,0.2);
      }
    }
  }


  .ant-select-focused {
      .ant-select-selector {
        box-shadow: 0 0 0 2px rgba(93,42,52,0.2)!important;
      }
    }

  .ant-select-selector {
    background-color: transparent !important;
    border-color: var(--red) !important;
    .ant-select-arrow {
      color: var(--red)!important;
    }
  }

  .ant-select-item-option:hover {
    background-color: #f1ece6;
  }

  .ant-select-item-option-selected {
    background-color: #f1ece6 !important;
  }
`