import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import MyLink from "../sub/myLink"

interface Props {
  open: boolean;
}

const MobileMenu: React.FC<Props> = ({ open }) => {
  const { nav } = useStaticQuery(graphql`
    query {
      nav: sanityNavigation {
        mobileMenu {
          _key
          linkText
          url
        }
        buttonLink {
          linkText
          url
        }
      }
    }
  `)

  return (
    <nav className={`mobile-menu ${open && "open"}`}>
      <ul>
        {nav.mobileMenu.map(link => (
          <li key={link._key}>
            <MyLink url={link.url}>{link.linkText}</MyLink>
          </li>
        ))}
        <li>
          <MyLink url={nav.buttonLink.linkText}>
            {nav.buttonLink.linkText}
          </MyLink>
        </li>
      </ul>
    </nav>
  )
}

export default MobileMenu
