import React from "react"
import Footer from "./footer/"
import Navigation from "./navigation/"
import "antd/dist/antd.css"
import GlobalStyle from "./globalStyles"
import CustomGlobalAntStyle from "./customGlobalAntStyle"
import CookieConsent from "react-cookie-consent"

interface Props {
  children: React.ReactNode
  location: any
}

const Layout: React.FC<Props> = ({ children, location }) => {
  var consetStyling = {
    color: "#fff",
    textDecoration: "underline",
  }

  return (
    <>
      <GlobalStyle />
      <CustomGlobalAntStyle />
      <Navigation location={location} />
      {children}
      <Footer location={location} />
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-tagmanager"
        style={{ background: "var(--red)" }}
        buttonStyle={{ background: "#fff", color: "var(--red)" }}
      >
        Lateral Partners uses cookies to improve your experience on our website.
        By clicking accept you will receive all cookies from the Lateral
        Partners website. You may adjust your browser settings if you do not
        wish to receive cookies{" "}
        <a style={consetStyling} href="/privacy-policy">
          Read our Website Privacy Policy here.
        </a>
      </CookieConsent>
    </>
  )
}

export default Layout
