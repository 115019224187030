import React, { useState, useEffect, useRef } from 'react'
import { RiArrowDownSLine } from "react-icons/ri"
import MyLink from "../sub/myLink"

interface Props {
  linkList: {
    mainLink: any
    subLinks: any
  }
}

const NavDropdownLinks: React.FC<Props> = ({ linkList: link }) => {
  const [open, setOpen] = useState<boolean>(false)
  const targetClick = useRef<HTMLSpanElement>(null)

  const openDropdown = () => {
    setOpen(pre => !pre);
  }

  useEffect(() => {
    window.addEventListener("click", (event: any) => {
      if (targetClick.current !== null) {
        var isClickInside = targetClick.current.contains(event.target)

        if (!isClickInside) {
          setOpen(false)
        }
      }
    })
  }, []);

  return (
    <>
      <span className="nav-link" ref={targetClick} onClick={openDropdown}>
        {link.mainLink[0].buttonText}{" "}
        <RiArrowDownSLine className={open ? "open" : ""} />
      </span>
      <ul className={open ? "open" : ""}>
        {link.subLinks.map(subLink => (
          <li key={subLink._key}>
            <MyLink url={subLink.url} className="nav-sub-link">
              {subLink.linkText}
            </MyLink>
          </li>
        ))}
      </ul>
    </>
  )
}

export default NavDropdownLinks
