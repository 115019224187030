import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import MyLink from "../sub/myLink"
import NewsletterForm from "../newsletter/activeHostedForm"
import styled from "styled-components"

const SocialsContainer = styled.div<{ isMobile?: boolean }>`
  ${({ isMobile }) => (isMobile ? `display: none;` : `display: flex;`)};

  flex-direction: row;
  padding-top: 65px;
  @media only screen and (max-width: 1000px) {
    padding-top: 0px;
    margin-bottom: 50px;

    ${({ isMobile }) => (isMobile ? `display: flex;` : `display: none;`)};
  }
`

const ImageWrapper = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 20px;
`

const StyledImg = styled(Img)`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const FooterMenus = () => {
  const {
    footer: {
      footerMenus: { footerMenus },
      socialLinks: socialLinks,
    },
  } = useStaticQuery(graphql`
    query {
      footer: sanityFooter {
        footerMenus {
          footerMenus {
            _key
            menuTitle
            menuLinks {
              _key
              linkText
              url
            }
          }
        }
        socialLinks: socialLinks {
          socialLink
          socialIcon {
            asset {
              fluid {
                srcWebp
                srcSetWebp
                srcSet
                src
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className="footer-menus">
      {footerMenus.map((menu, index) => {
        const isLast = index === footerMenus.length - 1
        return (
          <div className="menu" key={menu._key}>
            <div>
              <h4>{menu.menuTitle}</h4>
              <ul>
                {menu.menuLinks.map(link => (
                  <li key={link._key}>
                    <MyLink url={link.url}>{link.linkText}</MyLink>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              {isLast && socialLinks && (
                <SocialsContainer>
                  {socialLinks.map(link => {
                    return (
                      <a
                        href={link.socialLink}
                        target="_blank"
                        className="socialLink"
                      >
                        <ImageWrapper>
                          <StyledImg fluid={link.socialIcon.asset.fluid} />
                        </ImageWrapper>
                      </a>
                    )
                  })}
                </SocialsContainer>
              )}
            </div>
          </div>
        )
      })}

      <div className="menu newsletter">
        <div>
          {socialLinks && (
            <SocialsContainer isMobile>
              {socialLinks.map(link => {
                return (
                  <a
                    href={link.socialLink}
                    target="_blank"
                    className="socialLink"
                  >
                    <ImageWrapper>
                      <StyledImg fluid={link.socialIcon.asset.fluid} />
                    </ImageWrapper>
                  </a>
                )
              })}
            </SocialsContainer>
          )}
        </div>
        <div>
          <h4>Newsletter</h4>
          <NewsletterForm />
        </div>
      </div>
    </div>
  )
}

export default FooterMenus
