import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Spin as Hamburger } from "hamburger-react"
import MyLink from "../sub/myLink"
import NavDropdownLinks from "./navDropdownLinks"
import MobileMenu from "./mobileMenu"
import NavigationBar from "./navigationStyle"

const Navigation: React.FC<{ location: any }> = ({ location }) => {
  // states ====================
  const [navColor, setNavColor] = useState<string>("")
  const [mobileOpenMenu, setMobileOpenMenu] = useState<boolean>(false)

  // query data ================
  const {
    nav: { logo, menu, buttonLink },
  } = useStaticQuery(graphql`
    query {
      nav: sanityNavigation {
        logo {
          altText
          asset {
            originalFilename
            url
            fluid(maxWidth: 200) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
        menu {
          _key
          mainLink {
            ... on SanityButton {
              _key
              buttonText
            }
            ... on SanityLink {
              _key
              linkText
              url
            }
          }
          subLinks {
            linkText
            url
            _key
          }
        }
        buttonLink {
          linkText
          url
        }
      }
    }
  `)

  // functions =================
  const navColorUpdate = () => {
    if (window.location.pathname === "/") {
      window.scrollY >= 200 ? setNavColor("red") : setNavColor("white")
      return
    }
    window.scrollY >= 200 ? setNavColor("red") : setNavColor("white")
  }

  // event listener ============
  useEffect(() => {
    window.addEventListener("resize", () => {
      setMobileOpenMenu(false)
    })
  }, [])

  useEffect(() => {
    if (window.location.pathname === "/") {
      setNavColor("white")
    } else {
      setNavColor("white")
    }
    setMobileOpenMenu(false)
    window.removeEventListener("scroll", navColorUpdate)
    window.addEventListener("scroll", navColorUpdate)
  }, [location])

  // react elements ============
  return (
    <NavigationBar navColor={navColor}>
      <div className="nav-content">
        <div className="header-links">
          <MyLink url="/" className="logo">
            <img
              className="logo-icon"
              src={logo.asset.url}
              alt={logo.altText}
            />
          </MyLink>
          <div className="right-container">
            <div>
              <nav className="desktop-menu">
                <ul>
                  {menu.map(link => {
                    if (link.mainLink[0].__typename === "SanityLink") {
                      return (
                        <li key={link._key}>
                          <MyLink
                            url={link.mainLink[0].url}
                            className="nav-link"
                          >
                            {link.mainLink[0].linkText}
                          </MyLink>
                        </li>
                      )
                    } else {
                      return (
                        <li key={link._key}>
                          <NavDropdownLinks linkList={link} />
                        </li>
                      )
                    }
                  })}
                </ul>
              </nav>
            </div>

            <MyLink
              url={buttonLink.url}
              className={`right-button theme-btn block ${
                (navColor === "transparent" && "white") ||
                (navColor === "white" && "red") ||
                (navColor === "red" && "white")
              }`}
            >
              {buttonLink.linkText}
            </MyLink>

            <Hamburger
              toggled={mobileOpenMenu}
              toggle={setMobileOpenMenu}
              direction="left"
              rounded
              color={
                (navColor === "transparent" && "var(--white)") ||
                (navColor === "red" && "var(--white)") ||
                (navColor === "white" && "var(--red)")
              }
            />
          </div>
        </div>
      </div>

      <MobileMenu open={mobileOpenMenu} />
    </NavigationBar>
  )
}

export default Navigation
