import React from "react";
import { Link } from "gatsby";

interface Props {
  children: React.ReactNode;
  url: string;
  className?: string;
}

const MyLink: React.FC<Props> = ({ children, url, className }) => {
  if (url.indexOf("http://") === 0 || url.indexOf("https://") === 0) {
    return (
      <a href={url} target="_blank" className={className}>
        {children}
      </a>
    );
  }

  if (url.indexOf("mailto:") === 0 || url.indexOf("tel:") === 0 || url.indexOf("#") === 0) {
    return (
      <a href={url} className={className}>
        {children}
      </a>
    );
  }

  return (
    <Link to={url} className={className} activeClassName="active">
      {children}
    </Link>
  );
};

export default MyLink;
