import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import MyLink from "../sub/myLink"



const FooterTop = () => {
  const {
    footer: { footerTop },
  } = useStaticQuery(graphql`
    query MyQuery {
      footer: sanityFooter {
        footerTop {
          description
          buttons {
            _key
            linkText
            url
          }
        }
      }
    }
  `)

  return (
    <div className="footer-top">
      <p>{footerTop.description}</p>
      <div className="buttons">
        {footerTop.buttons.length && footerTop.buttons.map(button => (
          <MyLink
            className="theme-btn block white"
            key={button._key}
            url={button.url}
          >
            {button.linkText}
          </MyLink>
        ))}
      </div>
    </div>
  )
}

export default FooterTop
