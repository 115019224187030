import React from "react";
import Layout from "./src/components/shared/layout";
import "firebase/firestore";
import "firebase/functions";
import "firebase/auth";

import "./static/fonts/fonts.css";

if (process.env.NODE_ENV === "production") {
  console.log = () => { };
  console.error = () => { };
}

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}
