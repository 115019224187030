import { colors } from "@util/constants";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`




/* @font-face {
  font-family: "thin";
  src: url("/fonts/BwModelica-Thin.woff");
}

@font-face {
  font-family: "regular";
  src: url("/fonts/BwModelica-Regular.woff");
}

@font-face {
  font-family: "medium";
  src: url("/fonts/BwModelica-Medium.woff");
}

@font-face {
  font-family: "bold";
  src: url("/fonts/BwModelica-Bold.woff");
} */

  /* CSS variable */
  :root {
    --red: #551E2B;
    --light-red: #F1ECE6;
    --white: #F7F2EE;
    --nav-height: 7rem;
  }

  ::selection {
    color: var(--white);
    background-color: var(--red);
  }

  /* overide different browser' default settings */
  * {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  html {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    font-size: 62.5%;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    width: 100%;
    height: 100%;
    font-size: 18px;
    font-family: regular;
    font-weight: bold;
    background-color: var(--white);
    overflow-x: hidden;
  
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: bold ,-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  ul, li, p {
    padding: 0;
    margin: 0;
  }

  a {
    text-decoration: none;
    color: var(--red);

    &:hover {
      color: inherit;
    }
  }

  // custom global reuseable style/class name
  .container {
    width: 80%;
    max-width: 1400px;
    margin: 0 auto;
  }

  .subpage-container {
    padding-top: var(--nav-height);
  }

  .theme-btn {
    display: block;
    transition: all 0.2s ease-out;
    border: 0;
    cursor: pointer;
    width: fit-content;
    text-align: center;
    background-color: transparent;

    &.white {
      color: white;
      border-color: white;
    }

    &.red {
      color: var(--red);
      border-color: var(--red);
    }

    &.block {
      padding: 0.8rem 2rem;
      border-width: 0.18rem;
      border-style: solid;
      border-radius: 0.4rem;

      &.red:hover, &.red.active {
        color: var(--white);
        background-color: var(--red)!important;
      }

      &.white:hover, &.white.active {
        color: var(--red);
        background-color: var(--white)!important;
      }
    }

    &.inline {
      &:hover {
        opacity: 0.5!important;
      }
    }

    &:active {
      opacity: 0.5;
    }

    &:focus {
      outline: none;
    }
  }

  button {
    border: none;
    background: transparent;
    cursor: pointer;
  }
`;

export default GlobalStyle;
