import React, { useState, useEffect } from "react"
import FooterTop from "./footerTop"
import FooterMenus from "./footerMenus"
import FooterBottomBar from "./footerBottomBar"

import FooterTag from "./indexStyle"

const Footer: React.FC<{ location: any }> = ({location}) => {
  const [showFooterTop, setShowFooterTop] = useState(true);

  useEffect(() => {
    window.location.pathname === "/contact-us/" ? setShowFooterTop(false) : setShowFooterTop(true);
  }, [location])

  return (
    <FooterTag>
      <div className="container">
        {showFooterTop && <FooterTop />}
        <FooterMenus />
        <FooterBottomBar />
      </div>
    </FooterTag>
  )
}

export default Footer
