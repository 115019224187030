import React, { useEffect } from 'react'
import NewsLetterStyle from "./activeFormStyle"

const ActiveHostedForm = () => {
  useEffect(() => {
    const script = document.createElement("script")

    script.src = "https://lateralpartners.activehosted.com/f/embed.php?id=6"
    script.async = true

    document.body.appendChild(script)
  }, [])

  return (
    <NewsLetterStyle>
      <div className="_form_6"></div>
    </NewsLetterStyle>
  )
}

export default ActiveHostedForm
