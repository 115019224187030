import styled from "styled-components"

export default styled.div<{ navColor: string }>`
  ${props =>
    (props.navColor === "white" &&
      `--color1: var(--red); --color2: var(--white);`) ||
    (props.navColor === "red" &&
      `--color1: var(--white); --color2: var(--red);`) ||
    (props.navColor === "transparent" &&
      `--color1: var(--white); --color2: var(--red);`)}

  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  background-color: ${props =>
    props.navColor === "transparent" ? "transparent" : "var(--color2);"};
  transition: background-color 0.2s ease-out;
  z-index: 10;
  padding: 30px 40px;

  @media (max-width: 1000px) {
    padding: 20px 30px;
  }

  .nav-content {
    max-width: 1500px;
    margin: auto;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    z-index: 10;

    .header-links {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .logo {
        display: block;
        height: 41px;

        .logo-icon {
          ${props =>
            (props.navColor === "red" || props.navColor === "transparent") &&
            "filter: brightness(10);"}

          @media only screen and (max-width: 1000px) {
            height: 30px;
          }
        }
      }
      .right-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      nav.desktop-menu {
        @media (max-width: 900px) {
          display: none;
        }

        a,
        span {
          cursor: pointer;
          color: var(--color1);

          &:hover {
            opacity: 0.9;
          }
        }
        & > ul {
          display: flex;

          & > li {
            position: relative;

            span.nav-link svg {
              position: relative;
              top: 0.3rem;
              transform: rotate(0);
              transition: transform 0.2s ease-out;

              &.open {
                transform: rotate(180deg);
              }
            }

            .nav-link {
              display: block;

              padding-right: 35px;
              user-select: none;
              :hover {
                opacity: 0.6;
              }
            }

            & > ul {
              position: absolute;
              display: none;
              bottom: -40px;
              left: 0;
              width: 20rem;
              transform: translateY(100%);
              border-radius: 5px;
              ${props =>
                props.navColor === "transparent" &&
                "border-top-left-radius: 5px; border-top-right-radius: 5px;"}
              overflow: hidden;

              &.open {
                display: block;
              }

              & > li {
                &:first-child a {
                  padding-top: 2rem;
                }

                &:last-child a {
                  padding-bottom: 2rem;

                  &::after {
                    display: none;
                  }
                }

                & > a {
                  position: relative;
                  display: block;
                  padding: 1rem 2rem;
                  background-color: var(--red);
                  color: var(--white);

                  &::after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    display: block;
                    height: 1px;
                    width: calc(100% - 4rem);
                    background-color: var(--white);
                    transform: translateX(-50%);
                    opacity: 0.2;
                  }
                }
              }
            }
          }
        }
      }
    }

    .right-button {
      @media (max-width: 900px) {
        display: none;
      }
    }

    .hamburger-react {
      @media (min-width: 901px) {
        display: none;
      }

      div {
        height: 0.1rem !important;
      }
    }
  }

  nav.mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    padding-top: var(--nav-height);
    z-index: 9;
    background-color: var(--color2);
    transform: translateX(100%);
    transition: transform 0.2s ease-out;
    overflow-y: scroll;

    &.open {
      transform: translateX(0);
    }

    ul {
      width: 60%;
      margin: 0 auto;
      padding: 3rem;

      li {
        a {
          position: relative;
          display: block;
          padding: 1.5rem 0;
          color: var(--color1);
          font-size: 1.5rem;

          &:hover {
            opacity: 0.6;
          }

          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 1px;
            background-color: var(--color1);
            opacity: 0.2;
          }
        }
      }
    }
  }
`
